import * as React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import Layout from "../components/layout";
import Block from "../components/block";
import Seo from 'gatsby-plugin-wpgraphql-seo';

// markup
const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query IndexPageQuery {
			home: wpPage(isFrontPage: {eq: true}) {
				id
				blocks {
					name
					attributesJSON
					innerBlocks {
						name
						attributesJSON
						innerBlocks {
							name
							attributesJSON
						}
					}
				}
				seo {
					title
					metaDesc
					focuskw
					metaKeywords
					metaRobotsNoindex
					metaRobotsNofollow
					opengraphTitle
					opengraphDescription
					opengraphImage {
						altText
						sourceUrl
						srcSet
					}
					twitterTitle
					twitterDescription
					twitterImage {
						altText
						sourceUrl
						srcSet
					}
					canonical
					cornerstone
					schema {
						articleType
						pageType
						raw
					}
				}
			}
		}	
	`);

	const {
		home: {
			blocks
		}
	} = data;

	return (
		<Layout>
			<Seo post={data.home} />
			{
				blocks && blocks.map((block, index) => {
					return (
						<Block key={index} block={block} post={data.home} />
					)
				})
			}
		</Layout>
	);
};

export default IndexPage;
